import httpClient from "@/services/http.js";

const backendBaseUrl =
  process.env.VUE_APP_BACKEND_URL + process.env.VUE_APP_REST_VERSION;
const backendUrl = backendBaseUrl + "/search-requests";

class SearchRequestService {
  syncToServer(uid, answers, contact, userId) {
    const payload = {
      uid: uid,
      input: answers,
      contact: contact,
      user_id: userId,
      date: Date.now()
    };
    console.log(payload);
    httpClient.post(backendUrl + "/upsert", payload);
  }
}

export default new SearchRequestService();
