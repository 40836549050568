<template>
  <div class="search-request-container">
    <div v-if="inputStep==1" class="search-request-flow-container">
      <search-request-flow
       :uid="uid"
       @sync="updateTruth"
       @all-questions-answered="nextStep"
      />
    </div>
    <div v-else-if="inputStep==2" class="contacts-question-container">
      <contact-information
       @done="addContact"/>
    </div>
    <div v-else-if="inputStep==3" class="contacts-question-container">
      <success />
    </div>
   </div>
</template>

<script>
import SearchRequestFlow from '@/views/search_request/SearchRequestFlow.vue'
import ContactInformation from '@/views/search_request/ContactInformation.vue'
import Success from '@/views/search_request/Success.vue'

import persistenceService from '@/services/request-service.js'
import slackService from '@/services/slack-service.js'

import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'SearchRequestInput',
  components: {
    ContactInformation,
    SearchRequestFlow,
    Success,
  },
  data() {
    return {
      inputStep: 1,
      uid: null,
      contact: null,
    }
  },
  created() {
    this.uid = uuidv4()
  },
  methods: {
    updateTruth(obj){
      this.truth = obj.truth
    },
    syncToServer() {
      persistenceService.syncToServer(this.uid, this.truth, this.contact, this.$username)
      slackService.sendNewSearchRequestNotification(this.$username, this.uid, this.contact.companyName)
    },
    nextStep() {
      this.inputStep++
    },
    addContact(contact){
      this.contact = contact
      this.status = "submitted"
      this.syncToServer()
      this.nextStep()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/shared.scss';

.main {
  padding: 50px 20px;
  position: relative;
  min-height: calc(100vh - 80px);
}

.back-button {
  position: absolute;
  left: 10px;
  top: 10px;
  padding: 10px;
  z-index: 100;
}

.logo {
  width: 300px;
  margin: auto;
  max-width: 100vw;
}

.header {
  padding: 50px 0px;
}

.logout {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%);
  width: calc(100% - 40px);
}

.loading {
  padding: 20px;
}
</style>
