<template>
  <div class="contact-information-view-container">
    <div class="main-header title">
      Teilen Sie uns die Kontaktdaten ihres Kunden mit 
    </div>
    <div class="content">
      <div class="contact">
        <div class="contact-item">
          <label for="company-name" class="input-label">
            Firma
          </label>
          <div class="corners">
            <input 
              id="company-name" 
              type="text" 
              class="input" 
              v-model="companyName"
            >
          </div>
        </div>
        <div class="contact-item">
          <label for="contact-name" class="input-label">
            Ansprechpartner
          </label>
          <div class="corners">
            <input 
              id="contact-name" 
              type="text" 
              class="input" 
              v-model="contactName"
            >
          </div>
        </div>
        <div class="contact-item">
          <label for="email" class="input-label">
            Email
          </label>
          <div class="corners">
            <input 
              id="email" 
              type="text" 
              class="input" 
              v-model="email"
            >
          </div>
        </div>
        <div class="contact-item">
          <label for="mobile-nr" class="input-label">
            Mobiltelefon
          </label>
          <div class="corners">
            <input 
              id="mobile-nr" 
              type="text" 
              class="input" 
              v-model="mobile"
            >
          </div>
        </div>
        <div class="confirm">
          <label class="container">
            Der Kunde hat der Weiterleitung der Fahrzeug- und Kontaktdaten zugestimmt
            <input type="checkbox" v-model="confirmed">
            <span class="checkmark"></span>
          </label>
        </div>
      </div>

      <div class="submit">
        <div 
          class="button primary" 
          :class="{ disabled: !dataValid }"
          @click="submit()"
        >
          Weiter
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactInformation',
  data() {
    return {
      companyName: '',
      contactName: '',
      email: '',
      mobile: '',

      confirmed: false,
    }
  },
  methods: {
    submit() {
      if(!this.dataValid) {
        return
      }
      const contact = { 
        companyName: this.companyName,
        contactName: this.contactName,
        email: this.email,
        mobile: this.mobile
      }
      this.$emit("done", contact)
    }
  },
  computed: {
    dataValid() {
      return this.companyName != '' && this.contactName != '' && (this.email != '' || this.mobile != '') && this.confirmed
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/shared.scss';

.contact-information-view-container {
  padding: 50px 0px;
}

.content {
  padding: 50px;
  text-align: left;
}

.contact {
  .contact-item {
    margin-bottom: 20px;
  }
}

.submit {
  padding: 50px 0px;
}

.input {
  border: none;
  border-radius: 0;
  background-color: white;
  color: $primary;
  text-align: right;
}
.corners {
  padding: 10px;
}

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

.container:hover input ~ .checkmark {
  background-color: #ccc;
}

.container input:checked ~ .checkmark {
  background-color: #2196F3;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.confirm {
  margin-top: 40px;
}
</style>
